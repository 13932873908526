import { get} from "../util/http"

export function getImages(p, s){
    return get({
        url: "/api/images",
        data: {
            p,
            s
        }
    });
}

export function getRecommendImages(s){
    return get({
        url: "/api/recommend-images",
        data: {
            s
        }
    });
}

export function getImagesByIc(ic, p, s){
    return get({
        url: "/api/images",
        data: {
            ic,
            p,
            s
        }
    });
}

export function getImage(imageId){
    return get({
        url: "/api/image",
        data: {
            id: imageId
        }
    });
}