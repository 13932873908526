/**
 *  
 */
import axios from "axios";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    withCredentials: false,
    timeout: 60 * 1000,
    // responseType: "arraybuffer",
});

// request拦截器
service.interceptors.request.use(config => {
    // 是否需要设置 token
    // const isToken = (config.headers || {}).isToken === false;
    // const token = store.getters.token || ""; 
    // const token = store.getters.token || ""; 
    // if (token && !isToken) {
    //     config.headers.Authorization = "Bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
    // }
    // get请求映射params参数
    if (config.method === "get" && config.params) {
        let url = config.url + "?" + tansParams(config.params);
        url = url.slice(0, -1);
        config.params = {};
        config.url = url;
    }
    return config;
}, error => {
    Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = res.data.msg || "系统未知错误，请反馈给管理员";
    // 二进制数据则直接返回
    if (res.request.responseType === "blob" || res.request.responseType === "arraybuffer") {
        return res.data;
    }
    if (code === 401 || res.status == 401) {
        return Promise.reject(new Error("无效的登录信息，或者登录信息已过期，请重新登录。"));
    } else if (code === 500) {
        return Promise.reject(new Error(msg));
    } else if (code !== 200) {
        return Promise.reject(new Error("error"));
    }
    return res.data;
}, error => {
    let { message } = error;
    if (message.includes("401")) {
        message = "无效的登录信息，或者登录信息已过期，请重新登录。"
    } else if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    return Promise.reject(error);
});

/**
* 参数处理
* @param {*} params  参数
*/
function tansParams(params) {
    let result = "";
    for (const propName of Object.keys(params)) {
        const value = params[propName];
        let part = encodeURIComponent(propName) + "=";
        if (value !== null && typeof (value) !== "undefined") {
            if (typeof value === "object") {
                for (const key of Object.keys(value)) {
                    if (value[key] !== null && typeof (value[key]) !== "undefined") {
                        let params = propName + "[" + key + "]";
                        let subPart = encodeURIComponent(params) + "=";
                        result += subPart + encodeURIComponent(value[key]) + "&";
                    }
                }
            } else {
                result += part + encodeURIComponent(value) + "&";
            }
        }
    }
    return result;
}

export function get({ url, data = {}, headers = {} }) {
    return service({ url, method: "get", params: data, headers });
}

export function post({ url, data = {}, headers = {} }) {
    return service({ url, method: "post", data, headers });
}

export function put({ url, data = {}, headers = {} }) {
    return service({ url, method: "put", data, headers });
}

export function del({ url, headers = {} }) {
    return service({ url, method: "delete", headers });
}

export default service;