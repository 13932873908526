import { getImagesByIc } from "../api/image"

// 声明变量
const state = {
    images: [],
    collectId: "",
    curImageUrl: "",
    curImageId: "",
    curPageNum: 1,
    curPageSize: 20,
    curIndex: 0,
    requesting: false,
    finished: false,
};
const getters = {
    icImageUrl: (state) => state.curImageUrl || "",
    icImageRequesting: (state) => state.requesting,
};
// 修改变量（state不能直接赋值修改，只能通过mutations）
const mutations = {
};
// mutations的值由actions传入
const actions = {
    LoadCollectionImages({ state }, collectId) {
        state.curPageNum = 1
        state.collectId = collectId
        return buildImages(state).then(() => {
            if (state.images.length > 0) {
                state.curImageId = state.images[0].id;
                state.curImageUrl = state.images[0].url;
                state.curIndex = 0;
            }
        })
    },
    NextIcImage({ state }) {
        return new Promise((resolve) => {
            let reloadImages = setCurrentIcImage(state, 1);
            if (reloadImages) {
                state.curPageNum = state.curPageNum + 1
                buildImages(state).then(() => {
                    setCurrentIcImage(state, 1)
                    resolve(state.icImageUrl)
                })
            } else {
                resolve(state.icImageUrl)
            }
        });
    },
    PrevIcImage({ state }) {
        return new Promise((resolve) => {
            setCurrentIcImage(state, -1);
            resolve(state.icImageUrl)
        });
    }
};

const setCurrentIcImage = (state, inx) => {
    let inx2 = state.curIndex + inx;
    if (inx2 < 0) {
        return false; //  reload images 
    } else if (inx2 >= state.images.length) {
        return true;
    }
    state.curImageId = state.images[inx2].id;
    state.curImageUrl = state.images[inx2].url;
    state.curIndex = inx2;
    return false;
}

const buildImages = (state) => {
    return new Promise((resolve, reject) => {
        if (state.requesting || state.finished) {
            return resolve([]);
        }
        state.requesting = true;
        state.images = state.images || [];
        getImagesByIc(state.collectId, state.curPageNum, state.curPageSize || 20).then(resp => {
            let images = (resp.data || []).filter(d => d.url);
            for (let i = 0; i < images.length; i++) {
                state.images.push(images[i]);
            }
            if (images.length == 0) {
                state.finished = true;
            }
            resolve([])
        }, reject).finally(() => {
            state.requesting = false;
        })
    })
}

export default {
    state,
    mutations,
    actions,
    getters,
};