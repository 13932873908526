import { getImages, getImage, getRecommendImages } from "../api/image"

// 声明变量
const state = {
    imageId: "",
    imageUrl: "",
    images: [],
    images1: [],
    images2: [],
    currentPageNum: 1,
    currentPageSize: 20,
    requesting: false,
    finished: false,
};
const getters = {
    imageUrl: (state) => state.imageUrl || "",
    homeImageRequesting: (state) => state.requesting,
    images1: (state) => state.images1 || [],
    images2: (state) => state.images2 || []
};
// 修改变量（state不能直接赋值修改，只能通过mutations）
const mutations = {
};
// mutations的值由actions传入
const actions = {
    RecommendImages({ state }) {
        state.currentPageNum = 1
        return buildRecommendImages(state).then(() => buildImages(state), () => { }).finally(() => {
            // if (state.images.length > 0) {
            //     state.imageId = state.images[0].id;
            //     state.imageUrl = state.images[0].url;
            // }
        })
    },
    NextRecommentImages({ state }) {
        if (state.requesting) {
            return Promise.resolve([])
        }
        state.currentPageNum = state.currentPageNum + 1
        return buildImages(state)
    },
    GetImageUrl({ state }, imageId) {
        state.imageId = ""
        state.imageUrl = ""
        return new Promise((resolve, reject) => {
            getImage(imageId).then(resp => {
                state.imageId = resp.data.id
                state.imageUrl = resp.data.url
                resolve(resp.data.url)
            }, reject)
        })
    }
};

const buildRecommendImages = (state) => {
    return new Promise((resolve, reject) => {
        if (state.requesting || state.finished) {
            return reject();
        }
        state.requesting = true;
        state.images = state.images || [];
        getRecommendImages(state.currentPageSize || 20).then(resp => {
            state.requesting = false;
            let images = (resp.data || []).filter(d => d.url);
            if (images.length == 0) {
                resolve()
                return;
            }
            resetImages(state, images)
            if (images.length < state.currentPageSize) {
                resolve()
            } else {
                reject();
            }
        }, () => {
            resolve()
        }).finally(() => {
            state.requesting = false;
        })
    })
}

const buildImages = (state) => {
    return new Promise((resolve, reject) => {
        if (state.requesting || state.finished) {
            return resolve([]);
        }
        state.requesting = true;
        state.images = state.images || [];
        getImages(state.currentPageNum, state.currentPageSize || 20).then(resp => {
            state.requesting = false;
            let images = (resp.data || []).filter(d => d.url);
            if (images.length == 0) {
                state.finished = true;
                resolve([])
                return;
            }
            resetImages(state, images)
            resolve([])
        }, reject).finally(() => {
            state.requesting = false;
        })
    })
}

const resetImages = (state, images) => {
    for (let i = 0; i < images.length; i++) {
        state.images.push(images[i]);
    }
    state.images1 = [];
    state.images2 = [];
    let len = state.images.length % 2 == 0 ? state.images.length : state.images.length - 1;
    for (let i = 0; i < len; i = i + 2) {
        state.images1.push(state.images[i])
        if (i + 1 < state.images.length) {
            state.images2.push(state.images[i + 1])
        }
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
};