<template>
  <div ref="target">
    <van-overlay v-if="requesting" :show="requesting">
      <div class="wrapper">
         <van-loading  type="spinner" size="12"  />
      </div>
    </van-overlay>
    <img v-else v-lazy="imageUrl" />
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSwipe } from "@vueuse/core";

const store = useStore();
const route = useRoute();
const router = useRouter();
const imageUrl = computed(() => store.getters.imageUrl);
const requesting = ref(false)
const target = ref(null);
const onSwipeEnd = (e, direction) => {
    if(direction === 'right') {
      router.back();
    }
}

const onLoad = () => {
    requesting.value = true;
    store.dispatch("GetImageUrl", route.params.id).finally(() => {
      requesting.value = false;
    })
}

onMounted(onLoad);
useSwipe(target, {
  passive: false,
  onSwipeEnd
});

</script>

<style scoped>
body {
  background: #f5f5f5;
}
img {
  width: 100%;
  min-height: 100%;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>