import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/Home.vue'
import ImageView from '../views/Image.vue'
import IcImageView from '../views/IC.vue'

const routes = [
  { path: '/', component: HomeView },
  { path: '/i/:id', component: ImageView },
  { path: '/ic/:id', component: IcImageView },
]

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

export default router;