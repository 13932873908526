<template>
  <van-row  :gutter="[12, 12]" class="waterfall">
    <van-col span="12">
      <div class="image-item" v-for="image in images1" :key="image.id">
        <img v-lazy="image.url" @click="showImageView(image)" />
      </div>
    </van-col>
    <van-col span="12">
      <div class="image-item" v-for="image2 in images2" :key="image2.id">
        <img v-lazy="image2.url" @click="showImageView(image2)" />
      </div>
    </van-col>

    <van-col span="24" v-if="requesting" class="waterfall-bottom">
        <van-loading type="spinner" size="12" />
    </van-col>
  </van-row>
</template>

<script setup>
import { computed, onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { installBottomRefresh, uninstallBottomRefresh } from "../util/bottom"

const store = useStore();
const router = useRouter();
const images1 = computed(() => store.getters.images1);
const images2 = computed(() => store.getters.images2);
const requesting = computed(() => store.getters.homeImageRequesting);

const showImageView = (image) => {
  if(image.id.length === 6) {
    router.push("/ic/" + image.id);
  } else {
    router.push("/i/" + image.id);
  }
};

onMounted(() => {
  store.dispatch("RecommendImages");
  installBottomRefresh(100, () => { 
    store.dispatch("NextRecommentImages") 
  })
});

onBeforeUnmount(uninstallBottomRefresh)

</script>

<style scoped>
.waterfall {
  background: #f5f5f5;
  min-height: 600px;
  padding: 20px 12px 20px 12px;
}

.image-item {
  /* box-shadow: 0 4px 8px 0 rgba(141, 136, 136, 0.02),
    0 1px 3px 0 rgba(0, 0, 0, 0.02); */
  background: #fff;
  font-size: 12px;
  border-radius: 2px;
  margin-bottom: 8px;
}

.image-item img {
  width: 100%;
}

.waterfall-bottom {
  text-align: center;
}
</style>
