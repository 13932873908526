<template>
  <div ref="target">
    <van-overlay v-if="requesting" :show="requesting">
      <div class="wrapper">
         <van-loading  type="spinner" size="12"  />
      </div>
    </van-overlay>
    <img v-else v-lazy="imageUrl" />
  </div>
</template>
<script setup>
import { useStore } from "vuex";
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useSwipe } from "@vueuse/core";
import { showToast } from 'vant';


const store = useStore();
const route = useRoute();
const router = useRouter();
const imageUrl = computed(() => store.getters.icImageUrl);
const requesting = computed(() => store.getters.icImageRequesting);
// const requesting = computed(() => true);

const target = ref(null);
const onSwipeEnd = (e, direction) => {
    if(direction === 'up' || direction === 'left') {
        showToast("next")
        store.dispatch("NextIcImage").then(() => {}, () => {
          showToast("没有更多图片！")
        })
    } else if(direction === 'down') {
        store.dispatch("PrevIcImage").then(() => {}, () => {})
    } else if(direction === 'right') {
      router.back();
    }
}
onMounted(() => store.dispatch("LoadCollectionImages", route.params.id));

useSwipe(target, {
  passive: false,
  onSwipeEnd
});

</script>

<style scoped>
body {
  background: #f5f5f5;
}
img {
  width: 100%;
  min-height: 100%;
}
.wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
</style>