// 滚动条在Y轴上的滚动距离
function getScrollTop() {
    let bodyScrollTop = 0, documentScrollTop = 0;
    if (document.body) {
        bodyScrollTop = document.body.scrollTop;
    }
    if (document.documentElement) {
        documentScrollTop = document.documentElement.scrollTop;
    }
    return (bodyScrollTop - documentScrollTop > 0) ? bodyScrollTop : documentScrollTop;
}

//文档的总高度
function getScrollHeight() {
    let bodyScrollHeight = 0, documentScrollHeight = 0;
    if (document.body) {
        bodyScrollHeight = document.body.scrollHeight;
    }
    if (document.documentElement) {
        documentScrollHeight = document.documentElement.scrollHeight;
    }
    return (bodyScrollHeight - documentScrollHeight > 0) ? bodyScrollHeight : documentScrollHeight;
}

//浏览器视口的高度
function getWindowHeight() {
    return document.compatMode == "CSS1Compat" ? document.documentElement.clientHeight : document.body.clientHeight;
}

//判断是否滚动到底部
function isBottom(distance) {
    return getScrollTop() + getWindowHeight() + (distance || 0) >= getScrollHeight();
}

let bottomCallback = null;

const installBottomRefresh = (distance, callback) => {
    bottomCallback = (e) => {
        if( isBottom(distance)) {
            callback(e)
        }
    }
    window.addEventListener('scroll', bottomCallback)
}

const uninstallBottomRefresh = () => {
    window.removeEventListener('scroll', bottomCallback)
}

export { isBottom, installBottomRefresh, uninstallBottomRefresh }

//引用 import {isBottom} from './isBottom.js'

//滚动加载使用
//vue中生命周期mounted执行
// window.addEventListener('scroll', this.loadMore) //this.loadMore为滚动加载的方法
//移除监听
//vue中生命周期beforeDestroy执行
// window.removeEventListener('scroll', this.loadMore)