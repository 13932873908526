import { createStore } from "vuex";

import Image from "./image"
import imageCollection from "./image-collection"

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        Image,
        imageCollection
    },
});


// // 声明变量
// const state = {
// };
// const getters = {
// };
// // 修改变量（state不能直接赋值修改，只能通过mutations）
// const mutations = {
// };
// // mutations的值由actions传入
// const actions = {
// };
// export default {
//     state,
//     mutations,
//     actions,
//     getters,
// };