import { createApp } from 'vue'
import store from "./store";
import router from "./router";
import App from './App.vue'
import Vant from 'vant'
import { Lazyload } from 'vant';
import 'vant/lib/index.css'

createApp(App)
    .use(store)
    .use(router)
    .use(Lazyload, {
        // loading: require('./assets/logo.png')
    })
    .use(Vant)
    .mount('#app')
